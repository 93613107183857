<template>
  <div>
    <v-row>
      <v-col offset="4" cols="4" class="text-center mt-5">
        <h2>
          La sesión ha sida cerrada con éxito. Puede navegar al inicio.
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col offset="4" cols="4">
        <v-btn block color="primary" @click="goToHome">
          Ir al Inicio
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapMutations} from 'vuex'

export default {
  name: "Logout",
  mounted() {
    this.setUser(false)
    this.setToken(false)
    this.setLogin(false)

    /*setTimeout(() => {
      this.goToHome()
    }, 1000)*/
  },
  methods: {
    ...mapMutations({
      setLogin: 'SET_LOGGED_IN',
      setUser: 'SET_USER',
      setToken: 'SET_TOKEN',
    }),
    goToHome () {
      this.$router.replace({
        path: '/',
      })
    }
  }
}
</script>

<style scoped>

</style>
